import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import gsap from 'gsap'

/**
 * Loaders
 */
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

/**
 * Base
 */
// Debug
//const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//scene.backgroundBlurriness = 0
//scene.backgroundIntensity = 1

//gui.add(scene, 'backgroundBlurriness').min(0).max(1).step(0.1).name('Background Blurriness')
//gui.add(scene, 'backgroundIntensity').min(0).max(10).step(0.001).name('Background Intensity')
//gui.add(scene.backgroundRotation, 'y').min(0).max(Math.PI * 2).step(0.01).name('Background Rotation on Y')

const spaces = [
    { name: 'Main Bedroom', folder: 'main-bedroom' },
    { name: 'Main Living Room', folder: 'main-living-room' },
    { name: 'Outdoor Living', folder: 'outdoor-living' },
]

let currentSpace = spaces[0]
let currentVersion = '1k'

/**
 * Environment map
 */
let environmentMap

function loadEnvironmentMap(space, version) {
    const newEnvironmentMap = cubeTextureLoader.load([
        `/environmentMaps/${space.folder}/${version}/WEBP/px.webp`,
        `/environmentMaps/${space.folder}/${version}/WEBP/nx.webp`,
        `/environmentMaps/${space.folder}/${version}/WEBP/py.webp`,
        `/environmentMaps/${space.folder}/${version}/WEBP/ny.webp`,
        `/environmentMaps/${space.folder}/${version}/WEBP/pz.webp`,
        `/environmentMaps/${space.folder}/${version}/WEBP/nz.webp`
    ])

    gsap.to(scene, {
        backgroundIntensity: 0,
        duration: 1,
        onComplete: () => {
            scene.background = newEnvironmentMap
            environmentMap = newEnvironmentMap
            gsap.to(scene, {
                backgroundIntensity: 1,
                duration: 1
            })
        }
    })
}

loadEnvironmentMap(currentSpace, currentVersion)

/**
 * UI
 */
const uiContainer = document.createElement('div')
uiContainer.style.position = 'absolute'
uiContainer.style.top = '10px'
uiContainer.style.left = '10px'
uiContainer.style.zIndex = '1000'
document.body.appendChild(uiContainer)

// Space selector
const spaceSelect = document.createElement('select')
spaces.forEach(space => {
    const option = document.createElement('option')
    option.value = space.folder
    option.textContent = space.name
    spaceSelect.appendChild(option)
})
spaceSelect.addEventListener('change', (event) => {
    currentSpace = spaces.find(space => space.folder === event.target.value)
    loadEnvironmentMap(currentSpace, currentVersion)
})
uiContainer.appendChild(spaceSelect)

// Version selector
const versionSelect = document.createElement('select')
const versions = ['1k', '2k']
versions.forEach(version => {
    const option = document.createElement('option')
    option.value = version
    option.textContent = version
    versionSelect.appendChild(option)
})
versionSelect.addEventListener('change', (event) => {
    currentVersion = event.target.value
    loadEnvironmentMap(currentSpace, currentVersion)
})
uiContainer.appendChild(versionSelect)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(4, 5, 4)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.y = 3.5
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
const tick = () => {
    // Time
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()